export type TLocationType = {
  code: string;
  displayName: string;
  id: string;
  note: string | null;
  squareUnit: string | null;
};

export type TParentLocation = {
  displayName: string;
  longDisplayName: string;
  id: string;
  name: string;
  number: number;
};

export type TRoomType = {
  code: string | null;
  displayName: string;
  id: string;
  note: string | null;
};

export type TAssociatedVirtualLocations = {
  id: string;
  name: string;
  displayName?: string;
  shortDisplayName?: string;
  parentId: string;
};

export type TLocation = {
  childrenIds: string[];
  class: string;
  description: string;
  displayName: string;
  id: string;
  isActive: boolean;
  isVirtual: boolean;
  associatedVirtualLocations: TAssociatedVirtualLocations[];
  locationType: TLocationType;
  longDisplayName: string;
  name: string;
  notes: string | null;
  number: number;
  pId: string | null;
  parentId: string | null;
  parentLocation: TParentLocation;
  roomType: TRoomType;
  sequence: number | null;
  shortDisplayName: string;
  squareUnit: string | null;
  status: string;
  tags: { name: string }[];
  title: string;
  value: string;
  virtualChildrenLocationIds: number[];
};

export type TQueryLocations = {
  page: {
    edges: Array<{
      node: TLocation;
    }>;
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
    totalCount: number;
  };
};

export type TQueryLocation = {
  GetLocationById: TLocation;
};

export type LocationFormProps = {
  isActive: boolean;
  parentId: string;
  locationTypeId: {
    value: string;
    label: string;
    code: string;
  };
  roomTypeId?: string;
  name?: string;
  description?: string;
  squareUnit?: number;
  notes?: string;
  sequence?: number;
  tags?:
    | string[]
    | {
        value: string;
      }[];
  isVirtual?: boolean;
  virtualChildrenLocationIds?: string[];
  associatedVirtualLocations?: TAssociatedVirtualLocations[];
};

export type TCurrentJob = {
  id: number;
  action?: string;
  items: {
    name: string;
    amount: number;
  }[];
  status: string;
  source: string;
  notes?: {
    id: number;
    note: string;
  }[];
  targetTime: string;
  completedAt?: string;
  assignee?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  department: {
    Id: number;
    departmentCode: string;
  }[];
  type: string;
  attachments?: [string];
  priority: string;
  isRushed: boolean;
};

export type Preference = {
  type: string;
  value: string;
  description: string;
};

export type Guest = {
  id: string;
  country?: string;
  externalGuestProfileId?: string;
  preferences?: [Preference];
  firstName?: string;
  lastName?: string;
  title?: string;
  type: string;
};

export type Note = {
  note: string;
};

export enum StatusType {
  'InHouse' = 'In House',
  'Arrival' = 'Arrival',
  'Departure' = 'Departure',
}

export enum ReservationStatus {
  'Unknown' = 'Unknown',
  'Reserved' = 'Reserved',
  'Cancelled' = 'Cancelled',
  'CheckedIn' = 'Checked In',
  'CheckedOut' = 'Checked Out',
}

export type ReservationDetail = {
  id: string;
  attributes: any;
  externalReservationId: string;
  reservationStatus: ReservationStatus;
  guests: Guest[];
  displayName: string;
  arrivalDate: string;
  departureDate: string;
  estimatedArrivalTime: string;
  estimatedDepartureTime: string;
  noOfAdults: number;
  noOfChildren: number;
  isDeleted: boolean;
  notes: Note[];
  preferences: Preference[];
  version: string;
  statusLabel: StatusType;
};

type User = {
  id: string;
  firstName: string;
  lastName: string;
};

type Metadata = {
  changes: [
    {
      [key: string]: any;
    },
  ];
};

export type Log = {
  aggregateId: string;
  aggregateType: string;
  eventType: string;
  aggregateVersion: string;
  action: string;
  recordedAt: string;
  user: User;
  metadata: Metadata;
};
