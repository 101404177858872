import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import config from 'config';

const { App: CapApp } = Plugins;

// This handles deeplinks coming in with custom url schemes.
// This allows us to open links like optiitopcat://localhost/settings for example
function AppUrlListener() {
  const history = useHistory();
  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      let splitData = data.url.split('localhost');
      // If there is only one entry, we should try the IOS UI URL
      if (splitData.length < 2) {
        splitData = data.url.split(config.REACT_APP_IOS_UI_URL);
      }
      const slug = splitData.pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
}

export default AppUrlListener;
